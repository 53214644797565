
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Safe from './Safe';

export default function HomeSection(props) {
  const config = props.config;
  return (
    <div className="region region-content-blocks">
      <div className="block block-bean first odd" id="block-bean-about-the-study">
        <div id="important-contribution" className="container-fluid pb-responsive">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <div className="entity entity-bean bean-content-section clearfix">
                <h2><Safe className="up" type="span" content={config.title} /></h2>
                <div className="content">
                  <div className="field field-name-field-content-section-body field-type-text-with-summary field-label-hidden">
                    <div className="field-items">
                      <div className="field-item even">
                        {renderParagraphs(config.text)}
                        <Safe type="h3" className="pb-30" content={config.subtitle} />
                        {config.refs &&
                          <ol>{config.refs.map((item, index) => <li key={`li-${index}`}>{item}</li>)}</ol>
                        }
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block block-bean last even" id="block-bean-qa">
        <div className="content-section-block container-fluid">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="bean-header-img">
                <StaticImage src="../images/home-lower.png" alt="why participate" className="img-responsive content-section-image" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <div className="entity entity-bean bean-content-section clearfix">
                <div className="content"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(items) {
  return items.map((item, index) => <Safe type="p" key={`p_${index}`} content={item} />);
}


import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Safe from './Safe';

export default function StudyDashboard(props) {
  const localized = props.localizerfn;
  const activeStudies = props.studies.filter(study => !Boolean(study.hideStudy));
  const groupedStudies = renderStudies(activeStudies.sort(compare), 2);

  const studyImages = {
    covid_flu: <StaticImage src="../images/covidflu_home.jpg" alt="Covid Flu Study" />,
    covid_rsv: <StaticImage src="../images/covidrsv_home.jpg" alt="Covid RSV Study" />,
    covid_nextgen: <StaticImage src="../images/welcomimg_1044.jpg" alt="Covid Next Generation Study" />,
    covidvariant: <StaticImage src="../images/1054_homeimg.jpg" alt="Covid Variant Study" />
  };

  return (
    <div className="container-fluid">
      <div id="the-studies">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <article className="node node-page view-mode-full clearfix node-1" about="/home" typeof="foaf:Document">
              <header><span property="dc:title" content="Home" className="rdf-meta element-hidden"></span></header>
              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                <div className="field-items">
                  <div className="field-item even" property="content:encoded">
                    <h2>{props.studyIntro.header}</h2>
                    {renderParagraphs(props.studyIntro.text)}
                    {groupedStudies.map((studyrow, index) => {
                      return (
                        <div key={`group_${index}`} className="row study-info-row">
                          {studyrow.map((item, index) => (
                            renderStudy(item, props.studyIntro.studylink, studyImages, localized)
                          ))}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderStudy(study, linkLabel, imagemap, localized) {
  if (study.siteurl) {
    return (
      <div key={study.slug} className="col-sm-6">
        <Link to={localized(study.linkto)}>{imagemap[study.slug]}</Link>
        <div className="study-info">
          <h3><Link to={localized(study.linkto)}><Safe type="span" content={study.tiletitle} /></Link></h3>
          <Safe type="p" content={study.description} />
          <div><Link className="btn btn-study-info" to={localized(study.linkto)}><Safe type="span" content={linkLabel} /></Link></div>
        </div>
      </div>
    );
  }
  // Otherwise display study tile with NO link
  return (
    <div key={study.slug} className="col-sm-6">
      <span>{imagemap[study.slug]}</span>
      <div className="study-info">
        <h3><Safe type="span" content={study.tiletitle} /></h3>
        <Safe type="p" content={study.description} />
      </div>
    </div>
  );
}

// Compare the studies by "seq" field
function compare(a, b) {
  if (a.seq<b.seq) return -1;
  else if (a.seq>b.seq) return 1;
  else return 0;
}

function renderStudies(studies, columns) {
  return studies.reduce((group, study, index) => {
    const isNewRow = ((index%columns)===0);
    const currRow = (isNewRow ? [] : group[group.length-1])
    currRow.push(study);
    if (isNewRow) group.push(currRow);
    return group;
    }, []);
}

function renderParagraphs(items) {
  if (!Array.isArray(items)) return null;
  return items.map((item, index) => <Safe type="p" key={`p_${index}`} content={item} />);
}
